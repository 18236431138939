// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

 @include font-face(
  $name: "icomoon",
  $file: "icomoon-webfont",
  $weight: 400,
  $style: normal,
  $type: woff
 );

@include font-face(
    $name: "FontAwesome",
    $file: "fa-regular-400",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "FontAwesome",
    $file: "fa-solid-900",
    $weight: 900,
    $style: normal,
    $type: woff
);
@include font-face(
    $name: "Poppins",
    $file: "Poppins-Black",
    $weight: 900,
    $style: normal,
    $type: woff
);
@include font-face(
    $name: "Poppins",
    $file: "Poppins-Bold",
    $weight: 700,
    $style: normal,
    $type: woff
);
@include font-face(
    $name: "Poppins",
    $file: "Poppins-Light",
    $weight: 400,
    $style: normal,
    $type: woff
);