// Height for Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;
$mobil-height: 50px;

.page-navi {

	// Off Canvas Styles
	//////////////////////////////
	@include breakpoint(medium, max) {
		background: $secondary;
		left: 50%;
		overflow-y: auto;
		position: fixed;
		top: 0;
		padding-top: calc(#{rem($mobil-height + 100px)});
		transform: translateX(-50%);
		transition: .6s;
		width: 0;
		z-index: 1000;
	    bottom: 0;
	    max-width: 100%;
	    nav {
		    .navi-main{
			    opacity:0;
			    margin:0 rem($base-gap);
			    display:flex;
			    flex-wrap:wrap;
			    align-content:flex-start;
			    height:100%;
			   > li {
			    		flex-basis:100%;
			    		opacity:0;
	    			   	transition-delay: .8s;
	    			   	a,span{
	    			   		color:$light;
	    			   		padding:rem(4px) rem($base-gap);
	    			   	}

			    	&.startseite, &.impressum, &.datenschutzerklaerung, &.kontakt {
						order:2;
			    	}
			    	&.leistungen, &.referenzen, &.unternehmen {
			    		order:1;
			    		font-size:rem(28px);
						text-transform:uppercase;
						&.unternehmen {
							margin-bottom:rem(40px);
						}
			    	}
			    }
		    }
		}
		.map-container{
			margin:rem(80px) auto 0 auto;
			width:80%;
    		opacity:0;
			span {
				color:$light;
				display:block;
				text-align:center;
				opacity:0;
				transition-delay:0.8s;
			}
			iframe{
				opacity:0;
				transition-delay:0.8s;
			}
		}
	}
	.navi-main {
		li {
			a, span{
	    		text-decoration:none;
	    		display:block;
	    	}
	    	&.hasSub{
	    		position:relative;
	    		flex-basis:auto;
	    		display:inline-block;
	    		&:before {
	    			content:"\ea2a";
	    			font-family:$icon-font2;
	    			transform:rotate(90deg) translateX(-50%);
					position:absolute;
					right:0;
					color:$light;
					font-size:rem(20px);
					top:rem(16px);
	    		}
	    		.sub {
	    			opacity:0;
	    			max-height:0;
	    			visibility:hidden;
	    			transition:0.3s ease-in-out;
					li {
						text-transform:none;
						font-size:rem(16px);
					}
					a {

					}
				}
	    	}
	    	
	    	&:hover {

				a {

				}
				&.hasSub {
					&:before {
	    				transform:rotate(-90deg);
	    			}
					.sub{
						max-height:rem(400px);
						opacity:1;
						visibility:visible;
		    			padding:rem(20px) 0;
					}
				}
			}

			@include breakpoint(medium, max){
				&.active {
					a.active {
						color:$medium;
						position:relative;
						&:after{
							content:"";
							width:20px;
							height:1px;
							background-color:$light;
							position:absolute;
							top:0;
							left:rem($base-gap);
						}
					}
				}
			}
		}
	}
	@include breakpoint(medium){
		margin-top:rem(50px);
		.navi-main {
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			li{
				text-transform:uppercase;
				padding:rem(4px) rem(7px);
				transition:0.3s;
				position:relative;
				a {
					letter-spacing:2px;
				}
				&:after {
		    		position:absolute;
		    		left:50%;
		    		bottom: 0;
		    		width:1px;
		    		background-color:rgba($light,0);
		    		content:"";
		    		height:1px;
		    		transition:0.3s;
		    		transform:translateX(-50%);
		    	}
		    	&.active {
			    	font-weight:700;
			    	.sub li {
			    		font-weight:400;
			    	}
			    }
			    &:hover {
			    	&:after {
			    		width:rem(30px);
			    		background-color:$light;
			    	}
			    }
				&.datenschutzerklaerung, &.impressum, &.anfahrt{
		    		display:none;
			    }
			    a, span {
			    	color:$light;
			    }
			    &.hasSub{
			    	padding-right:rem(30px);
			    	&:before {
			    		right:0;
			    		transform:translateY(-50%) rotate(90deg);
			    		top:50%;
			    		position:absolute;
			    	}
			    	.sub li.active & {
			    		&.active {
			    			font-weight:700;
			   			}
			    	}
			    	.sub{
			    		padding:rem(8px) 0;
			    		position:absolute;
			    		li{
			    			background-color:$light;
			    			padding:rem(5px) rem(10px);
			    			margin:rem(4px) 0;
			    			transition:0.3s;
			    			border:0;
			    			a {
			    				color:$dark;
			    				letter-spacing:0;
			    			}
			    			&:hover {
			    				background-color:$primary;
			    				a{
			    					color:$light;
			    				}
			    			}
			    			&.active {
			    				 a.active {
			    					color:$secondary;
			    					font-weight:700;
			    				}
			    				&:hover {
			    					a{
			    						color:$light;
			    					}
			    				}
			    			}
			    		}
			    	}
			    	&:hover {
			    		&:before{
			    			transform: translateY(-50%) rotate(-90deg);
			    		}
			    	}
			    }
			}
		}
	}
	@include breakpoint(large){
		.navi-main {
			li {
				a,span {
					font-size:rem(18px);
					letter-spacing:rem(4px);
				}
				&.hasSub{
			 		.sub{
			 			li{
			 				padding:0;
			 				a{
			 					padding:rem(7px) rem(12px);
			 				}
			 			}
			 		}
			 	}
			}
		}
	}
		@include breakpoint(giant){
		.navi-main {
			li {
				padding:rem(7px) rem(12px);
				&.hasSub{
					&:before {
						right:6px;
					}
			 	}
			}
		}
	}
	@include breakpoint(huge){
		margin-top: rem(60px);
		.navi-main {
			width:calc(100% + #{rem(24px)});
			left: rem(-12px);
			position:relative;
			li {
				a,span {
					font-size:rem(22px);
					letter-spacing:0.1em;
				}
				&.hasSub{
					.sub {
						li {
							a{
								font-size:rem(19px);
							}
						}
					}
				}
			}
		}
	}
}

// Mobile Menu Bar
//////////////////////////////
@include breakpoint(medium, max) {
	.mobile-bar-wrapper {	
		position: fixed;
		z-index: 1001;
		top: rem(40px); right:0; left:0; 

		> div {
			display: flex;
			align-items: center;
			justify-content: center;

			padding-right: rem($base-gap);
			padding-left: rem($base-gap);

			ul {
				display: flex;
				width: 100%;
				align-items: center;
				justify-content:stretch;
				height: rem(50px);
				color: $dark;
				border-radius: rem(5px);
				background: $primary;

				li {
					display:flex;
					flex-grow:1;
					padding: 0 rem($base-gap);

					> a, > span {
						font-size: rem(24px);

						> i {
							color: $light;
						}
					}

					> a[href^=tel], > span.phone {
						position: relative;
						text-decoration: none;
						color:$dark;
						letter-spacing: 1px;
						font-weight: normal;
						margin-right: rem($base-gap);
					}

					&.menu-content {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						font-size: rem(13px);

						label[for="navi-toggled"] {
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							span {
							  color:$light;
							}
							.navi-menu {
							height: 20px;
							width: 20px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: rem(10px);
							  > span {
							  	height: 2px;
								  width: rem(20px);
								  background-color: $light;
								  position: relative;
								  transition: all 0.3s ease-in-out;

								  &:before, &:after {
								  	content: "";
								  	height: 2px;
								    width: rem(20px);
								    position: absolute;
								    top: 0;
								    left: 0;
								    transition: all 0.3s ease-in-out;
								  }

								  &:before {
								    box-shadow: 0 rem(-7px) 0 0 $light;
							 		}

								  &:after {
								    box-shadow: 0 rem(7px) 0 0 $light;

								  }
							  }
							}
						}
					}
				}
			}
		}
	}
}
@include breakpoint(medium){
	.mobile-bar-wrapper {
		display:none;
	}
}

#navi-toggled {
	display: none;

	&:checked {
		~ * label[for="navi-toggled"] {
			> .navi-menu {
				> span {
					background-color: transparent;
					transform: rotate(360deg);
					transition: all 0.6s ease-in-out;

					&:before, &:after {
						top: 0;
						transition: all 0.3s ease-in-out;
					}

					&:before {
				    left: 6px;
				    box-shadow: 0 rem(-10px) 0 0 $light;
				    transform: translateY(6px) rotate(-45deg); 

				    @include breakpoint(large) {
				    	box-shadow: 0 rem(-10px) 0 0 $light;
				    }
				  }

				  &:after {
				    left: 6px;
				    box-shadow: 0 rem(10px) 0 0 $light;
				    transform: translateY(-8px) rotate(45deg);

				    @include breakpoint(large) {
				    	box-shadow: 0 rem(10px) 0 0 $light;
				    }
				  }
				}
			}
		}
		~ * .page-navi, ~ .page-navi {
			width:100%;
		    .navi-main{
		    	opacity:1;
		    	li{
		    		opacity:1;
		    	}
		    }
		    .map-container{
		    	opacity:1;
		    	iframe, span {
		    		opacity:1;
		    	}
		    }
		}
	}
}