.icon-default {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: $icon-font2;
  font-size: inherit;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-rendering: auto;
  text-transform: none;
}

.icon-3d:before {
  content: "\e900";
}
.icon-3d-glasses:before {
  content: "\e901";
}
.icon-24-hours:before {
  content: "\e902";
}
.icon-24-hours-steps:before {
  content: "\e903";
}
.icon-action:before {
  content: "\e904";
}
.icon-anchor:before {
  content: "\e905";
}
.icon-food-apple:before {
  content: "\e906";
}
.icon-around-clock:before {
  content: "\e907";
}
.icon-arrow-download:before {
  content: "\e908";
}
.icon-arrows-three:before {
  content: "\e909";
}
.icon-arrow-windows:before {
  content: "\e90a";
}
.icon-asia:before {
  content: "\e90b";
}
.icon-atomic-waste:before {
  content: "\e90c";
}
.icon-attention:before {
  content: "\e90d";
}
.icon-food-avocado:before {
  content: "\e90e";
}
.icon-award:before {
  content: "\e90f";
}
.icon-buggy-stroller:before {
  content: "\e910";
}
.icon-backpack:before {
  content: "\e911";
}
.icon-clockwise-counter:before {
  content: "\e912";
}
.icon-baggage:before {
  content: "\e913";
}
.icon-food-baguette:before {
  content: "\e914";
}
.icon-balloon:before {
  content: "\e915";
}
.icon-balloons:before {
  content: "\e916";
}
.icon-food-bananas:before {
  content: "\e917";
}
.icon-barcode:before {
  content: "\e918";
}
.icon-bar-gate:before {
  content: "\e919";
}
.icon-barrel:before {
  content: "\e91a";
}
.icon-bathtub:before {
  content: "\e91b";
}
.icon-battery-charge:before {
  content: "\e91c";
}
.icon-battery-empty:before {
  content: "\e91d";
}
.icon-battery-full:before {
  content: "\e91e";
}
.icon-battery-half:before {
  content: "\e91f";
}
.icon-beamer:before {
  content: "\e920";
}
.icon-bed:before {
  content: "\e921";
}
.icon-bed-crib:before {
  content: "\e922";
}
.icon-bed-double:before {
  content: "\e923";
}
.icon-bed-honeymoon:before {
  content: "\e924";
}
.icon-drink-beer:before {
  content: "\e925";
}
.icon-bell:before {
  content: "\e926";
}
.icon-bicycle-01:before {
  content: "\e927";
}
.icon-bicycle-02:before {
  content: "\e928";
}
.icon-bicycle-03:before {
  content: "\e929";
}
.icon-bicycle-04:before {
  content: "\e92a";
}
.icon-bicycle-05:before {
  content: "\e92b";
}
.icon-bicycle-06:before {
  content: "\e92c";
}
.icon-bicycle-07:before {
  content: "\e92d";
}
.icon-bicycle-08:before {
  content: "\e92e";
}
.icon-big-ben-london-england:before {
  content: "\e92f";
}
.icon-bin-trash:before {
  content: "\e930";
}
.icon-binder:before {
  content: "\e931";
}
.icon-binoculars:before {
  content: "\e932";
}
.icon-bio:before {
  content: "\e933";
}
.icon-clipchart-list:before {
  content: "\e934";
}
.icon-bone:before {
  content: "\e935";
}
.icon-bookmark:before {
  content: "\e936";
}
.icon-box:before {
  content: "\e937";
}
.icon-box-closed:before {
  content: "\e938";
}
.icon-box-open:before {
  content: "\e939";
}
.icon-food-bread-loaf:before {
  content: "\e93a";
}
.icon-bridge:before {
  content: "\e93b";
}
.icon-bug:before {
  content: "\e93c";
}
.icon-bus:before {
  content: "\e93d";
}
.icon-butterfly:before {
  content: "\e93e";
}
.icon-food-cake:before {
  content: "\e93f";
}
.icon-calculator:before {
  content: "\e940";
}
.icon-calender:before {
  content: "\e941";
}
.icon-camera:before {
  content: "\e942";
}
.icon-camera-film:before {
  content: "\e943";
}
.icon-campfire:before {
  content: "\e944";
}
.icon-camping:before {
  content: "\e945";
}
.icon-car:before {
  content: "\e946";
}
.icon-cards:before {
  content: "\e947";
}
.icon-car-fine:before {
  content: "\e948";
}
.icon-car-lens:before {
  content: "\e949";
}
.icon-cassette:before {
  content: "\e94a";
}
.icon-castle-germany:before {
  content: "\e94b";
}
.icon-castle-old:before {
  content: "\e94c";
}
.icon-cd-01:before {
  content: "\e94d";
}
.icon-cd-02:before {
  content: "\e94e";
}
.icon-cd-03:before {
  content: "\e94f";
}
.icon-chair:before {
  content: "\e950";
}
.icon-drink-champaign:before {
  content: "\e951";
}
.icon-drink-champaign-glasses:before {
  content: "\e952";
}
.icon-chart:before {
  content: "\e953";
}
.icon-chat-bubbles:before {
  content: "\e954";
}
.icon-chat-dots:before {
  content: "\e955";
}
.icon-chat-lines:before {
  content: "\e956";
}
.icon-check-box:before {
  content: "\e957";
}
.icon-check-box-small:before {
  content: "\e958";
}
.icon-sheet-list:before {
  content: "\e959";
}
.icon-check-postit:before {
  content: "\e95a";
}
.icon-food-cheese:before {
  content: "\e95b";
}
.icon-food-cheese-top:before {
  content: "\e95c";
}
.icon-chef:before {
  content: "\e95d";
}
.icon-food-cherry:before {
  content: "\e95e";
}
.icon-food-chicken:before {
  content: "\e95f";
}
.icon-food-chili:before {
  content: "\e960";
}
.icon-food-chocolate:before {
  content: "\e961";
}
.icon-chopsticks:before {
  content: "\e962";
}
.icon-church:before {
  content: "\e963";
}
.icon-cinema:before {
  content: "\e964";
}
.icon-circles:before {
  content: "\e965";
}
.icon-circles-row:before {
  content: "\e966";
}
.icon-clipchart:before {
  content: "\e967";
}
.icon-clock:before {
  content: "\e968";
}
.icon-clock-alarm:before {
  content: "\e969";
}
.icon-clock-time:before {
  content: "\e96a";
}
.icon-clock-timer:before {
  content: "\e96b";
}
.icon-clothes-baby:before {
  content: "\e96c";
}
.icon-clothes-belt:before {
  content: "\e96d";
}
.icon-clothes-boots:before {
  content: "\e96e";
}
.icon-clothes-dress:before {
  content: "\e96f";
}
.icon-clothes-dressshirt:before {
  content: "\e970";
}
.icon-clothes-flipflop:before {
  content: "\e971";
}
.icon-clothes-handbag:before {
  content: "\e972";
}
.icon-clothes-hat-man:before {
  content: "\e973";
}
.icon-clothes-hat-woman:before {
  content: "\e974";
}
.icon-clothes-highheel:before {
  content: "\e975";
}
.icon-clothes-jacket:before {
  content: "\e976";
}
.icon-clothes-jacket-winter:before {
  content: "\e977";
}
.icon-clothes-jeans:before {
  content: "\e978";
}
.icon-clothes-underwear:before {
  content: "\e979";
}
.icon-clothes-shirt:before {
  content: "\e97a";
}
.icon-clothes-shoe:before {
  content: "\e97b";
}
.icon-clothes-skirt:before {
  content: "\e97c";
}
.icon-clothes-suit:before {
  content: "\e97d";
}
.icon-clothes-tie:before {
  content: "\e97e";
}
.icon-clothes-top:before {
  content: "\e97f";
}
.icon-clothes-sneakers:before {
  content: "\e980";
}
.icon-clothes-tshirt:before {
  content: "\e981";
}
.icon-clothes-tshirt-long:before {
  content: "\e982";
}
.icon-clothes-bikini:before {
  content: "\e983";
}
.icon-cloud-down:before {
  content: "\e984";
}
.icon-cloud-up:before {
  content: "\e985";
}
.icon-cloud-up-arrow:before {
  content: "\e986";
}
.icon-cloverleaf:before {
  content: "\e987";
}
.icon-clubs:before {
  content: "\e988";
}
.icon-drink-cocktail:before {
  content: "\e989";
}
.icon-coffee:before {
  content: "\e98a";
}
.icon-coffee-bean:before {
  content: "\e98b";
}
.icon-coffee-maschine:before {
  content: "\e98c";
}
.icon-coffee-to-go:before {
  content: "\e98d";
}
.icon-columns:before {
  content: "\e98e";
}
.icon-compass:before {
  content: "\e98f";
}
.icon-cone:before {
  content: "\e990";
}
.icon-contact-book:before {
  content: "\e991";
}
.icon-container:before {
  content: "\e992";
}
.icon-craftsman-tools:before {
  content: "\e993";
}
.icon-food-croissant:before {
  content: "\e994";
}
.icon-crop:before {
  content: "\e995";
}
.icon-crown:before {
  content: "\e996";
}
.icon-cube:before {
  content: "\e997";
}
.icon-food-cupcake:before {
  content: "\e998";
}
.icon-cutlery:before {
  content: "\e999";
}
.icon-diamond:before {
  content: "\e99a";
}
.icon-diamonds:before {
  content: "\e99b";
}
.icon-dice:before {
  content: "\e99c";
}
.icon-dish-washer:before {
  content: "\e99d";
}
.icon-diving:before {
  content: "\e99e";
}
.icon-food-donut:before {
  content: "\e99f";
}
.icon-door:before {
  content: "\e9a0";
}
.icon-dots:before {
  content: "\e9a1";
}
.icon-download:before {
  content: "\e9a2";
}
.icon-drink:before {
  content: "\e9a3";
}
.icon-dvd:before {
  content: "\e9a4";
}
.icon-earth:before {
  content: "\e9a5";
}
.icon-eco-car:before {
  content: "\e9a7";
}
.icon-eco-gas:before {
  content: "\e9a8";
}
.icon-eco-global:before {
  content: "\e9a9";
}
.icon-eco-home:before {
  content: "\e9aa";
}
.icon-eco-lightbulb:before {
  content: "\e9ab";
}
.icon-food-egg:before {
  content: "\e9ac";
}
.icon-eiffel-paris-france:before {
  content: "\e9ad";
}
.icon-eject:before {
  content: "\e9ae";
}
.icon-elephant:before {
  content: "\e9af";
}
.icon-envelope:before {
  content: "\e9b0";
}
.icon-envelope-letter:before {
  content: "\e9b1";
}
.icon-equalizer:before {
  content: "\e9b2";
}
.icon-escalator-down:before {
  content: "\e9b3";
}
.icon-escalator-up:before {
  content: "\e9b4";
}
.icon-extern:before {
  content: "\e9b5";
}
.icon-eye:before {
  content: "\e9b6";
}
.icon-eye-fine:before {
  content: "\e9b7";
}
.icon-fan:before {
  content: "\e9b8";
}
.icon-fax:before {
  content: "\e9b9";
}
.icon-feather:before {
  content: "\e9ba";
}
.icon-feet:before {
  content: "\e9bb";
}
.icon-fence:before {
  content: "\e9bc";
}
.icon-filmcamera:before {
  content: "\e9bd";
}
.icon-filmroll:before {
  content: "\e9be";
}
.icon-film-tickets:before {
  content: "\e9bf";
}
.icon-first-aid:before {
  content: "\e9c0";
}
.icon-first-aid-cross:before {
  content: "\e9c1";
}
.icon-food-fish:before {
  content: "\e9c2";
}
.icon-flag:before {
  content: "\e9c3";
}
.icon-flash:before {
  content: "\e9c4";
}
.icon-flashlight:before {
  content: "\e9c5";
}
.icon-floor-boards:before {
  content: "\e9c6";
}
.icon-flower-pot:before {
  content: "\e9c7";
}
.icon-folder:before {
  content: "\e9c8";
}
.icon-font:before {
  content: "\e9c9";
}
.icon-fork-lift-truck:before {
  content: "\e9ca";
}
.icon-clockwise:before {
  content: "\e9cb";
}
.icon-free:before {
  content: "\e9cc";
}
.icon-fridge:before {
  content: "\e9cd";
}
.icon-food-fried-egg:before {
  content: "\e9ce";
}
.icon-fries:before {
  content: "\e9cf";
}
.icon-radio-transmission:before {
  content: "\e9d0";
}
.icon-gallery:before {
  content: "\e9d1";
}
.icon-gameboy:before {
  content: "\e9d2";
}
.icon-gaming:before {
  content: "\e9d3";
}
.icon-garage:before {
  content: "\e9d4";
}
.icon-gardening:before {
  content: "\e9d5";
}
.icon-food-garlic:before {
  content: "\e9d6";
}
.icon-gender-female:before {
  content: "\e9d7";
}
.icon-gender-male:before {
  content: "\e9d8";
}
.icon-gesture-pad-01:before {
  content: "\e9d9";
}
.icon-gesture-pad-02:before {
  content: "\e9da";
}
.icon-gesture-pad-03:before {
  content: "\e9db";
}
.icon-gesture-pad-04:before {
  content: "\e9dc";
}
.icon-gesture-pad-05:before {
  content: "\e9dd";
}
.icon-gesture-pad-06:before {
  content: "\e9de";
}
.icon-gesture-pad-07:before {
  content: "\e9df";
}
.icon-gesture-pad-08:before {
  content: "\e9e0";
}
.icon-gesture-phone-01:before {
  content: "\e9e1";
}
.icon-gesture-phone-02:before {
  content: "\e9e2";
}
.icon-gesture-phone-03:before {
  content: "\e9e3";
}
.icon-gesture-phone-04:before {
  content: "\e9e4";
}
.icon-gesture-phone-05:before {
  content: "\e9e5";
}
.icon-gesture-phone-06:before {
  content: "\e9e6";
}
.icon-gesture-phone-07:before {
  content: "\e9e7";
}
.icon-gesture-phone-08:before {
  content: "\e9e8";
}
.icon-gesture-phone-09:before {
  content: "\e9e9";
}
.icon-gesture-phone-10:before {
  content: "\e9ea";
}
.icon-gesture-phone-11:before {
  content: "\e9eb";
}
.icon-gesture-phone-12:before {
  content: "\e9ec";
}
.icon-gesture-phone-13:before {
  content: "\e9ed";
}
.icon-gesture-phone-14:before {
  content: "\e9ee";
}
.icon-glasses:before {
  content: "\e9ef";
}
.icon-global:before {
  content: "\e9f0";
}
.icon-global-worldwide:before {
  content: "\e9f1";
}
.icon-globe:before {
  content: "\e9f2";
}
.icon-clothes-gloves:before {
  content: "\e9f3";
}
.icon-graduation:before {
  content: "\e9f4";
}
.icon-food-grapes:before {
  content: "\e9f5";
}
.icon-hairdresser:before {
  content: "\e9f6";
}
.icon-hairdryer:before {
  content: "\e9f7";
}
.icon-food-hamburger:before {
  content: "\e9f8";
}
.icon-handcuffs:before {
  content: "\e9f9";
}
.icon-weights-lifting:before {
  content: "\e9fa";
}
.icon-hand-pointing:before {
  content: "\e9fb";
}
.icon-hand-stop:before {
  content: "\e9fc";
}
.icon-hanger:before {
  content: "\e9fd";
}
.icon-hd:before {
  content: "\e9fe";
}
.icon-hdr:before {
  content: "\e9ff";
}
.icon-heart:before {
  content: "\ea00";
}
.icon-heartbeat:before {
  content: "\ea01";
}
.icon-home:before {
  content: "\ea02";
}
.icon-food-honey:before {
  content: "\ea03";
}
.icon-food-hotdog:before {
  content: "\ea04";
}
.icon-hotel:before {
  content: "\ea05";
}
.icon-hotel-sign:before {
  content: "\ea06";
}
.icon-hourglass:before {
  content: "\ea07";
}
.icon-food-ice-cream:before {
  content: "\ea08";
}
.icon-iphone-lock:before {
  content: "\ea09";
}
.icon-ipod:before {
  content: "\ea0a";
}
.icon-jug:before {
  content: "\ea0b";
}
.icon-kettle:before {
  content: "\ea0c";
}
.icon-key:before {
  content: "\ea0d";
}
.icon-keyboard:before {
  content: "\ea0e";
}
.icon-knife:before {
  content: "\ea0f";
}
.icon-knife-block:before {
  content: "\ea10";
}
.icon-knife-set:before {
  content: "\ea11";
}
.icon-lamp:before {
  content: "\ea12";
}
.icon-larger:before {
  content: "\ea13";
}
.icon-leaf:before {
  content: "\ea14";
}
.icon-search-minus:before {
  content: "\ea15";
}
.icon-search-plus:before {
  content: "\ea16";
}
.icon-search-small:before {
  content: "\ea17";
}
.icon-liberty-nyc-usa:before {
  content: "\ea18";
}
.icon-scale-justice:before {
  content: "\ea19";
}
.icon-lightbulb:before {
  content: "\ea1a";
}
.icon-lighthouse:before {
  content: "\ea1b";
}
.icon-light-switch:before {
  content: "\ea1c";
}
.icon-line-arrow-down:before {
  content: "\ea1d";
}
.icon-line-arrow-left:before {
  content: "\ea1e";
}
.icon-line-arrow-right:before {
  content: "\ea1f";
}
.icon-line-arrows-two:before {
  content: "\ea20";
}
.icon-line-arrow-up:before {
  content: "\ea21";
}
.icon-arrows-backward:before {
  content: "\ea22";
}
.icon-arrows-forward:before {
  content: "\ea23";
}
.icon-line-left:before {
  content: "\ea24";
}
.icon-pause:before {
  content: "\ea25";
}
.icon-line-person:before {
  content: "\ea26";
}
.icon-line-person-chat:before {
  content: "\ea27";
}
.icon-line-person-two:before {
  content: "\ea28";
}
.icon-play:before {
  content: "\ea29";
}
.icon-line-right:before {
  content: "\ea2a";
}
.icon-lines-three:before {
  content: "\ea2b";
}
.icon-stop:before {
  content: "\ea2c";
}
.icon-link:before {
  content: "\ea2d";
}
.icon-list:before {
  content: "\ea2e";
}
.icon-ear:before {
  content: "\ea2f";
}
.icon-truck:before {
  content: "\ea30";
}
.icon-food-lobster:before {
  content: "\ea31";
}
.icon-lock:before {
  content: "\ea32";
}
.icon-lock-unlocked:before {
  content: "\ea33";
}
.icon-food-lollipop:before {
  content: "\ea34";
}
.icon-love:before {
  content: "\ea35";
}
.icon-magnet:before {
  content: "\ea36";
}
.icon-mailbox:before {
  content: "\ea37";
}
.icon-mailman:before {
  content: "\ea38";
}
.icon-person:before {
  content: "\ea39";
}
.icon-person-two:before {
  content: "\ea3a";
}
.icon-map:before {
  content: "\ea3b";
}
.icon-map-marked:before {
  content: "\ea3c";
}
.icon-map-marker:before {
  content: "\ea3d";
}
.icon-map-marker-a:before {
  content: "\ea3e";
}
.icon-map-marker-ab:before {
  content: "\ea3f";
}
.icon-map-marker-b:before {
  content: "\ea40";
}
.icon-map-marker-home:before {
  content: "\ea41";
}
.icon-map-marker-start-finish:before {
  content: "\ea42";
}
.icon-map-search:before {
  content: "\ea43";
}
.icon-massage:before {
  content: "\ea44";
}
.icon-food-meat:before {
  content: "\ea45";
}
.icon-microphone:before {
  content: "\ea46";
}
.icon-microphone-off:before {
  content: "\ea47";
}
.icon-microwave:before {
  content: "\ea48";
}
.icon-target:before {
  content: "\ea49";
}
.icon-mixer:before {
  content: "\ea4a";
}
.icon-mobile-vibe:before {
  content: "\ea4b";
}
.icon-moon:before {
  content: "\ea4c";
}
.icon-motorbike:before {
  content: "\ea4d";
}
.icon-mountains:before {
  content: "\ea4e";
}
.icon-food-mushroom:before {
  content: "\ea4f";
}
.icon-music-drumkit:before {
  content: "\ea50";
}
.icon-music-drums:before {
  content: "\ea51";
}
.icon-music-eguitar:before {
  content: "\ea52";
}
.icon-music-guitar:before {
  content: "\ea53";
}
.icon-music-harp:before {
  content: "\ea54";
}
.icon-music-piano:before {
  content: "\ea55";
}
.icon-music-piano-keys:before {
  content: "\ea56";
}
.icon-music-saxophone:before {
  content: "\ea57";
}
.icon-music-trumpet:before {
  content: "\ea58";
}
.icon-new:before {
  content: "\ea59";
}
.icon-newsletter:before {
  content: "\ea5a";
}
.icon-newspaper:before {
  content: "\ea5b";
}
.icon-note:before {
  content: "\ea5c";
}
.icon-off-switch:before {
  content: "\ea5d";
}
.icon-office-chair:before {
  content: "\ea5e";
}
.icon-office-chair-high:before {
  content: "\ea5f";
}
.icon-food-onion:before {
  content: "\ea60";
}
.icon-opera-sidney-australia:before {
  content: "\ea61";
}
.icon-food-orange:before {
  content: "\ea62";
}
.icon-organise:before {
  content: "\ea63";
}
.icon-outline-arrow-down-right:before {
  content: "\ea64";
}
.icon-outline-arrow-right:before {
  content: "\ea65";
}
.icon-outline-arrow-up-right:before {
  content: "\ea66";
}
.icon-oven:before {
  content: "\ea67";
}
.icon-parcel-delivery:before {
  content: "\ea68";
}
.icon-palm-tree:before {
  content: "\ea69";
}
.icon-pan:before {
  content: "\ea6a";
}
.icon-paper-clip:before {
  content: "\ea6b";
}
.icon-paper-plane:before {
  content: "\ea6c";
}
.icon-paper-plane-line:before {
  content: "\ea6d";
}
.icon-parking:before {
  content: "\ea6e";
}
.icon-food-pasta:before {
  content: "\ea6f";
}
.icon-pathfinder:before {
  content: "\ea70";
}
.icon-paw:before {
  content: "\ea71";
}
.icon-pay-dollar:before {
  content: "\ea72";
}
.icon-pay-euro:before {
  content: "\ea73";
}
.icon-pay-pound:before {
  content: "\ea74";
}
.icon-pay-yen:before {
  content: "\ea75";
}
.icon-pc-mouse:before {
  content: "\ea76";
}
.icon-peace:before {
  content: "\ea77";
}
.icon-food-pear:before {
  content: "\ea78";
}
.icon-pen:before {
  content: "\ea79";
}
.icon-pencil:before {
  content: "\ea7a";
}
.icon-people-info:before {
  content: "\ea7b";
}
.icon-food-pepper:before {
  content: "\ea7c";
}
.icon-person:before {
  content: "\ea7d";
}
.icon-person-talk:before {
  content: "\ea7e";
}
.icon-pets-dog:before {
  content: "\ea7f";
}
.icon-pets-banned:before {
  content: "\ea80";
}
.icon-phone:before {
  content: "\ea81";
}
.icon-phone-book:before {
  content: "\ea82";
}
.icon-photo:before {
  content: "\ea83";
}
.icon-photo-light:before {
  content: "\ea84";
}
.icon-photo-stack:before {
  content: "\ea85";
}
.icon-picture-list:before {
  content: "\ea86";
}
.icon-picture-text:before {
  content: "\ea87";
}
.icon-piggybank:before {
  content: "\ea88";
}
.icon-pin:before {
  content: "\ea89";
}
.icon-food-pineapple:before {
  content: "\ea8a";
}
.icon-pisa-italy:before {
  content: "\ea8b";
}
.icon-plane:before {
  content: "\ea8c";
}
.icon-plane-top:before {
  content: "\ea8d";
}
.icon-planner:before {
  content: "\ea8e";
}
.icon-plants:before {
  content: "\ea8f";
}
.icon-plates:before {
  content: "\ea91";
}
.icon-food-popcorn:before {
  content: "\ea92";
}
.icon-pot:before {
  content: "\ea93";
}
.icon-present:before {
  content: "\ea94";
}
.icon-food-pretzel:before {
  content: "\ea95";
}
.icon-purse:before {
  content: "\ea96";
}
.icon-puzzle:before {
  content: "\ea97";
}
.icon-pyramid:before {
  content: "\ea98";
}
.icon-pyramids-egypt:before {
  content: "\ea99";
}
.icon-quote:before {
  content: "\ea9a";
}
.icon-quote-bubble:before {
  content: "\ea9b";
}
.icon-radio:before {
  content: "\ea9c";
}
.icon-check-in-bell:before {
  content: "\ea9d";
}
.icon-ribbon:before {
  content: "\ea9e";
}
.icon-jesus-rio-brasil:before {
  content: "\ea9f";
}
.icon-rocket:before {
  content: "\eaa0";
}
.icon-rolling-pin:before {
  content: "\eaa1";
}
.icon-colosseum-rome-italy:before {
  content: "\eaa2";
}
.icon-arrow-around:before {
  content: "\eaa3";
}
.icon-circle-arrow-down:before {
  content: "\eaa4";
}
.icon-circle-backward:before {
  content: "\eaa5";
}
.icon-circle-check-small:before {
  content: "\eaa6";
}
.icon-circle-check:before {
  content: "\eaa7";
}
.icon-circle-close:before {
  content: "\eaa8";
}
.icon-circle-exclamation:before {
  content: "\eaa9";
}
.icon-circle-forward:before {
  content: "\eaaa";
}
.icon-circle-info:before {
  content: "\eaab";
}
.icon-circle-minus:before {
  content: "\eaac";
}
.icon-circle-plus:before {
  content: "\eaad";
}
.icon-circle-marker-down:before {
  content: "\eaae";
}
.icon-circle-marker-left:before {
  content: "\eaaf";
}
.icon-circle-marker-right:before {
  content: "\eab0";
}
.icon-circle-marker-up:before {
  content: "\eab1";
}
.icon-circle-question:before {
  content: "\eab2";
}
.icon-circle-banned:before {
  content: "\eab3";
}
.icon-rss:before {
  content: "\eab4";
}
.icon-ruler:before {
  content: "\eab5";
}
.icon-ruler-half:before {
  content: "\eab6";
}
.icon-ruler-l:before {
  content: "\eab7";
}
.icon-sale:before {
  content: "\eab8";
}
.icon-food-salt:before {
  content: "\eab9";
}
.icon-sausage:before {
  content: "\eaba";
}
.icon-save:before {
  content: "\eabb";
}
.icon-exit:before {
  content: "\eabc";
}
.icon-food-shrimp:before {
  content: "\eabd";
}
.icon-scissors:before {
  content: "\eabe";
}
.icon-search:before {
  content: "\eabf";
}
.icon-mechanical-tools:before {
  content: "\eac0";
}
.icon-settings:before {
  content: "\eac1";
}
.icon-sewing:before {
  content: "\eac2";
}
.icon-sheet:before {
  content: "\eac3";
}
.icon-sheet-chart:before {
  content: "\eac4";
}
.icon-sheet-text:before {
  content: "\eac5";
}
.icon-sheet-marked:before {
  content: "\eac6";
}
.icon-sheet-search:before {
  content: "\eac7";
}
.icon-sheet-text-justify:before {
  content: "\eac8";
}
.icon-ship:before {
  content: "\eac9";
}
.icon-shop:before {
  content: "\eaca";
}
.icon-shop-cart-in:before {
  content: "\eacb";
}
.icon-shop-cart-out:before {
  content: "\eacc";
}
.icon-shop-bag:before {
  content: "\eacd";
}
.icon-shop-basket:before {
  content: "\eace";
}
.icon-shower:before {
  content: "\eacf";
}
.icon-signpost:before {
  content: "\ead0";
}
.icon-skateboard:before {
  content: "\ead1";
}
.icon-wood-plane:before {
  content: "\ead2";
}
.icon-slideshow:before {
  content: "\ead3";
}
.icon-smaller:before {
  content: "\ead4";
}
.icon-smily:before {
  content: "\ead5";
}
.icon-smoking-banned:before {
  content: "\ead6";
}
.icon-smoking-allowed:before {
  content: "\ead7";
}
.icon-sms:before {
  content: "\ead8";
}
.icon-social:before {
  content: "\ead9";
}
.icon-social-pad:before {
  content: "\eada";
}
.icon-social-pc:before {
  content: "\eadb";
}
.icon-socket:before {
  content: "\eadc";
}
.icon-sort-az:before {
  content: "\eadd";
}
.icon-sorting:before {
  content: "\eade";
}
.icon-sort-za:before {
  content: "\eadf";
}
.icon-sound:before {
  content: "\eae0";
}
.icon-sound-off:before {
  content: "\eae1";
}
.icon-sound-on:before {
  content: "\eae2";
}
.icon-food-soup:before {
  content: "\eae3";
}
.icon-spades:before {
  content: "\eae4";
}
.icon-spa-lotus:before {
  content: "\eae5";
}
.icon-sport-baseball:before {
  content: "\eae6";
}
.icon-sport-basketball:before {
  content: "\eae7";
}
.icon-sport-bowling:before {
  content: "\eae8";
}
.icon-sport-boxing:before {
  content: "\eae9";
}
.icon-sport-football-american:before {
  content: "\eaea";
}
.icon-sport-football:before {
  content: "\eaeb";
}
.icon-sport-tabletennis:before {
  content: "\eaec";
}
.icon-sport-tennis:before {
  content: "\eaed";
}
.icon-stairs-down:before {
  content: "\eaee";
}
.icon-stairs-up:before {
  content: "\eaef";
}
.icon-star:before {
  content: "\eaf0";
}
.icon-stats-01:before {
  content: "\eaf1";
}
.icon-stats-02:before {
  content: "\eaf2";
}
.icon-stats-03:before {
  content: "\eaf3";
}
.icon-stats-04:before {
  content: "\eaf4";
}
.icon-stats-05:before {
  content: "\eaf5";
}
.icon-stats-06:before {
  content: "\eaf6";
}
.icon-stats-07:before {
  content: "\eaf7";
}
.icon-stats-08:before {
  content: "\eaf8";
}
.icon-stats-09:before {
  content: "\eaf9";
}
.icon-stats-10:before {
  content: "\eafa";
}
.icon-stats-11:before {
  content: "\eafb";
}
.icon-stats-12:before {
  content: "\eafc";
}
.icon-stats-13:before {
  content: "\eafd";
}
.icon-stethoscope:before {
  content: "\eafe";
}
.icon-stocks:before {
  content: "\eaff";
}
.icon-stocks-down:before {
  content: "\eb00";
}
.icon-stocks-up:before {
  content: "\eb01";
}
.icon-stop-sign:before {
  content: "\eb02";
}
.icon-stove:before {
  content: "\eb03";
}
.icon-food-strawberry:before {
  content: "\eb04";
}
.icon-street:before {
  content: "\eb05";
}
.icon-sun:before {
  content: "\eb07";
}
.icon-sunglasses:before {
  content: "\eb08";
}
.icon-sunrise:before {
  content: "\eb09";
}
.icon-sunset:before {
  content: "\eb0a";
}
.icon-surveillance:before {
  content: "\eb0b";
}
.icon-sport-swimming:before {
  content: "\eb0c";
}
.icon-table:before {
  content: "\eb0d";
}
.icon-tab-water:before {
  content: "\eb0e";
}
.icon-tag:before {
  content: "\eb0f";
}
.icon-taj-mahal-india:before {
  content: "\eb10";
}
.icon-target-arrow:before {
  content: "\eb11";
}
.icon-taxi:before {
  content: "\eb12";
}
.icon-tea-bag:before {
  content: "\eb13";
}
.icon-teapot:before {
  content: "\eb14";
}
.icon-temp:before {
  content: "\eb15";
}
.icon-temp-drop:before {
  content: "\eb16";
}
.icon-temp-high:before {
  content: "\eb17";
}
.icon-temple-greece:before {
  content: "\eb18";
}
.icon-temp-low:before {
  content: "\eb19";
}
.icon-temp-mid:before {
  content: "\eb1a";
}
.icon-temp-rise:before {
  content: "\eb1b";
}
.icon-tent:before {
  content: "\eb1c";
}
.icon-text:before {
  content: "\eb1d";
}
.icon-text-center:before {
  content: "\eb1e";
}
.icon-text-justify:before {
  content: "\eb1f";
}
.icon-text-left:before {
  content: "\eb20";
}
.icon-text-right:before {
  content: "\eb21";
}
.icon-theatre:before {
  content: "\eb22";
}
.icon-tombstone:before {
  content: "\eb23";
}
.icon-thumbs-up:before {
  content: "\eb24";
}
.icon-food-toast:before {
  content: "\eb25";
}
.icon-toaster:before {
  content: "\eb26";
}
.icon-toilette:before {
  content: "\eb27";
}
.icon-tooth:before {
  content: "\eb28";
}
.icon-traffic-light:before {
  content: "\eb29";
}
.icon-train:before {
  content: "\eb2a";
}
.icon-transfer-bus:before {
  content: "\eb2b";
}
.icon-tree:before {
  content: "\eb2c";
}
.icon-tv:before {
  content: "\eb2d";
}
.icon-tv-hd:before {
  content: "\eb2e";
}
.icon-typewriter:before {
  content: "\eb2f";
}
.icon-umbrella:before {
  content: "\eb30";
}
.icon-uni-library:before {
  content: "\eb31";
}
.icon-up-down-load:before {
  content: "\eb32";
}
.icon-upload:before {
  content: "\eb33";
}
.icon-usb:before {
  content: "\eb34";
}
.icon-vcard:before {
  content: "\eb35";
}
.icon-video:before {
  content: "\eb36";
}
.icon-video-camera:before {
  content: "\eb37";
}
.icon-video-cut:before {
  content: "\eb38";
}
.icon-video-film:before {
  content: "\eb39";
}
.icon-vinyl:before {
  content: "\eb3a";
}
.icon-waiter:before {
  content: "\eb3b";
}
.icon-washing-machine:before {
  content: "\eb3c";
}
.icon-food-watermelon:before {
  content: "\eb3d";
}
.icon-wc-restroom:before {
  content: "\eb3e";
}
.icon-weather-cloud:before {
  content: "\eb3f";
}
.icon-weather-fog:before {
  content: "\eb40";
}
.icon-weather-night:before {
  content: "\eb41";
}
.icon-weather-rain:before {
  content: "\eb42";
}
.icon-weather-rain-snow:before {
  content: "\eb43";
}
.icon-weather-snow:before {
  content: "\eb44";
}
.icon-weather-storm:before {
  content: "\eb45";
}
.icon-weather-sun:before {
  content: "\eb46";
}
.icon-weather-sun-cloud:before {
  content: "\eb47";
}
.icon-weather-sun-rain:before {
  content: "\eb48";
}
.icon-weather-sun-snow:before {
  content: "\eb49";
}
.icon-weather-sun-storm:before {
  content: "\eb4a";
}
.icon-weather-wind:before {
  content: "\eb4b";
}
.icon-website:before {
  content: "\eb4c";
}
.icon-wedding-ring:before {
  content: "\eb4d";
}
.icon-wedding-rings:before {
  content: "\eb4e";
}
.icon-wheel:before {
  content: "\eb4f";
}
.icon-wheelchair:before {
  content: "\eb50";
}
.icon-whisk:before {
  content: "\eb51";
}
.icon-whistle:before {
  content: "\eb52";
}
.icon-wifi:before {
  content: "\eb53";
}
.icon-drink-wine-bottle:before {
  content: "\eb54";
}
.icon-drink-wine-leaf:before {
  content: "\eb55";
}
.icon-yin-yang:before {
  content: "\eb56";
}
