// PAGE WRAP

// Container Fixed
//////////////////////////////
.container-fixed{
	@include breakpoint(medium){
		position:fixed;
		top:0;
		left:0;
		z-index:999;
		width:100%;
		&.is_reduced, body.nojs & {
			background-color:$dark;
			&:before {
				background-image: url('../images/layout/teaser-2-huge.jpg');
				background-size:cover;
				content:"";
				position:absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				opacity:0.5;
			}
			.page-navi {
				margin-top:rem(20px);
				padding-bottom:rem(20px);
			}
		}
	}
}
// HEADER
//////////////////////////////
.header {
	position:relative;
	padding-top:rem($menu-bar + 80px);
	padding-bottom:rem(50px);
	position: relative;
	width: 100%;
	background-color:$dark;
	&:before {
		content:"";
		background-image:url("../images/layout/header.jpg");
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center;
		width:100%;
		height:100%;
		position:absolute;
		left:0;
		top:0;
		opacity:0.5;
	}
	.border-one{
		border:1px solid $light;
		padding:rem(12px);
		.branding {
			background-color:$light;
			padding:rem(12px);
			display:table;
			margin:-12px auto 0 auto;
			img{
				display:block;
				height:rem(70px);
			}
		}
		.claim {
			color:$light;
			text-align:center;
			display:block;
			margin-top:rem(30px);
			font-size:rem(20px);
			line-height:rem(30px);
			strong, a{
				display:block;
			}
			a{
				color:$light;
				text-decoration:none;
				font-size:rem(16px);
				margin-top:rem(10px);
				line-height:rem(26px);
				&:before{
					content:"\ea2a";
					font-family:$icon-font2;
				}
			}
			span{
				text-transform:uppercase;
			}
		}
		.white-bg{
			background-color:$light;
			margin:rem(30px) rem(-12px) rem(-12px) rem(-12px);
			padding:rem($base-gap);
			a {
				text-decoration:none;
			}
			> span, > a {
				display:block;
				margin:rem(5px) 0;
				position:relative;
				padding-left:rem(25px);
					&:before {
						position:absolute;
						top:50%;
						left:0;
						transform:translateY(-50%);
					}
				&.opening{
					font-size:rem(15px);
					&:before {
						content:"\e968";
						margin-right:rem(5px);
						@extend .icon-default;
					}
				}
				&.phone{
					&:before {
						content:"\ea81";
						margin-right:rem(5px);
						@extend .icon-default;
					}
				}
				&.mobil{
					&:before {
						content:"\ea4b";
						@extend .icon-default;
					}
					a.phone, > span.phone{
						padding-left:0;
					}
				}
				&.email {
					font-size:rem(14px);
					&:before {
						content:"\e9b1";
						@extend .icon-default;
					}
				}
			}
		}
	}
	.scroll-down {
		display:none;
	}
	body:not(.index) & {
		.claim {
			display:none;
		}
	}
	@include breakpoint(small){
		&:before {
			background-image:url("../images/layout/header-small.jpg");
		}
		.border-one {
			.claim {
				font-size:rem(34px);
				line-height:rem(44px);
				margin-top:rem(70px);
				span{
					font-size:rem(27px);
				}
				a{
					margin-top:rem(20px);
				}
			}
			.white-bg {
				display:flex;
				flex-wrap:wrap;
				margin-top:rem(70px);
				span, a{
					flex-basis:50%;
					&.opening, &.email {
						font-size:rem(16px);
					}
				}
			}
		}
	}
	@include breakpoint(medium){
		position:relative;
		.border-one {
			margin:rem(40px) 0 rem(100px) 0;
			.white-bg {
				display:flex;
				flex-wrap:wrap;
				justify-content:space-around;
			}
			.claim {
				> a{
					transition:0.3s;
					position:relative;
					display:table;
					margin:auto;
					&:after{
						content:"";
						width:1%;
						height:1px;
						background-color:rgba($primary,0);
						position:absolute;
						left:0;
						bottom:-4px;
					}
					&:hover {
						&:after{
							width:100%;
							background-color:$light;
						}
					}
				}
			}
		}
		.scroll-down {
			position:absolute;
			bottom:rem(70px);
			left:50%;
			transform:translateX(-50%);
			display:table;
			border:1px solid $light;
			border-radius:(15px);
			width:rem(21px);
			height:rem(44px);
			&:before {
				content:"\ea2a";
				font-family:$icon-font2;
				transform:translateX(-50%) rotate(90deg);
				position:absolute;
				left:50%;
				top:25%;
				color:$light;
			    animation-name: scroll-down;
    			animation-duration: 1s;
    			animation-iteration-count: infinite;
			}
		}
		body:not(.index) &{
			padding: rem(71px) 0 rem(20px) 0;
			.opening, .white-bg > .phone {
				display:inherit;
			}
			.border-one{
				margin: rem(50px) 0;
			}
		}
	}
	@include breakpoint(large){
		&:before {
			background-image:url("../images/layout/header-large.jpg");
		}
		.border-one{
			margin:rem(40px) 0 rem(136px) 0;
		}
	}
	@include breakpoint(giant){
		padding-top:rem(115px);
		.border-one{
			.claim {
				font-size:rem(40px);
				line-height:rem(50px);
				span{
					font-size:rem(34px);
				}
				a {
					font-size:rem(18px);
				}
			}
			.white-bg {
				max-width: 90%;
    			margin-left: 5%;
    			span, a {
    				font-size:rem(18px);
    			}
    			a.email, span.opening {
    				font-size:rem(18px);
    			}
			}
		}
	}
	@include breakpoint(extra){
		.border-one{
			.claim {
				font-size:rem(60px);
				line-height:rem(70px);
				margin-top:rem(138px);
				span{
					font-size:rem(40px);
					letter-spacing:rem(3px);
				}
			}
			.branding{
				padding:rem(17px);
				img {
					height:rem(83px);
				}
			}
			.white-bg {
				margin-top:rem(105px);
				span,a {
					font-size:rem(18px);
					flex-basis:auto;
				}
			}
		}
		.scroll-down {
			bottom:rem(83px);
		    width: rem(26px);
			height: rem(53px);
		}
	}
	@include breakpoint(huge){
		&:before {
			background-image:url("../images/layout/header-huge.jpg");
		}
		body:not(.index) &{
			.border-one{
				margin: rem(70px) 0;
			}
			.white-bg{
				margin-top:rem(40px);
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	margin-top:rem(70px);

	ul, dl {
		@extend .styled-list;
	}
	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
	.bg-cont{
		background-image:url('../images/layout/holz-bg-tease-one.jpg');
		background-size:cover;
		padding:rem(120px) 0;
		position:relative;
		display:block;
		margin-bottom:rem(40px);
		&:before {
			content:"";
			width:90%;
			height:90%;
			border:1px solid $light;
			position:absolute;
			left:5%;
			top:5%;
		}
		span:not(.topspan){
			color:$light;
			z-index:2;
			position:absolute;
			left:50%;
			top:calc(50% + #{rem(15px)});
			font-size:rem(30px);
			line-height:rem(40px);
			transform:translate(-50%,-50%);
			display:block;
			text-align:center;
		}
		.topspan {
			z-index:2;
			position:absolute;
			top:5%;
			left:50%;
			transform:translateX(-50%);
			display:table;
			background-color:$light;
			padding: rem(9px) rem(16px);
			text-decoration:none;
		}
	}
	.full-bg {
		position:relative;
		margin:rem(70px) 0;
		background:$dark;
		text-align:center;
		padding:rem(40px) 0;
		&:before {
			content:"";
			background-image:url('../images/layout/teaser-1.jpg');
			background-size:cover;
			width:100%;
			height:100%;
			position:absolute;
			top:0;
			left:0;
			opacity:0.5;
			background-attachment:fixed;
		}
		.container{
			padding:rem(20px) 0 rem(70px) 0;
			border:1px solid $light;
			span.cont {
				position:relative;
				z-index:2;
				color:$light;
				display:block;
				text-decoration:none;
				max-width:80%;
				left:10%;
			}
			span.head {
				font-weight:900;
				font-size:rem(20px);
				color:$light;
			}
		
		}
		span.bottom-span{
			z-index:2;
			position:absolute;
			bottom:0;
			left:50%;
			transform:translateX(-50%);
			display:table;
			background-color:$light;
			text-decoration:none;
			color:$primary;
			padding: rem(9px) rem(16px);
		}
		a {
			display:block;
			text-decoration:none;
			height:100%;
		}
		.cont {
			text-transform:uppercase;
			letter-spacing:0.1em;
			font-size:rem(14px);
		}
		 body:not(.index) & {
			margin:rem(40px) 0;
		} 
	}
	.bg-cont-two {
		@extend .bg-cont;
		background-image:url('../images/holzarbeiten-werkeln.jpg');
		background-position:center;
	}
	@include breakpoint(tiny){
		.bg-cont{
			padding:rem(200px) 0;
		}
	}
	@include breakpoint(small){
		margin-top:rem(90px);
		.plant-container {
			background-image:url('../images/layout/pflanze-gestaltung.png');
			background-size:100% auto;
			width:rem(279px);
			height:100%;
			background-repeat:no-repeat;
			position:absolute;
			bottom:0;
			right:0;
		}
		.full-bg {
			margin-bottom:rem(100px);
			&:before {
				background-image:url('../images/layout/teaser-1-small.jpg');
			}
			.container{
				span.head {
					font-size:rem(23px);
				}
			}
		}
		.bg-cont-two {
			margin-bottom:rem(110px);
			&:after{
				background-image:url('../images/layout/holz-spaehne.png');
				background-position:bottom;
				bottom:rem(-54px);
			}
		}
	}
	@include breakpoint(medium){
		.bg-cont{
			margin-bottom:0;
			span{
				opacity:1;
				visibility:visible;
				transition:0.3s;
				&.topspan{
					transition: 0.6s;
				}
			}
			&:hover {
				span:not(.topspan){
					opacity:0;
					visibility:hidden;
				}
				.topspan{
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					&:hover {
						background-color:$primary;
						color:$light;
					}
				}
			}
		}
		.plant-container {
			display:none;
		}
		.full-bg {
			padding:rem(90px) 0;
			&:after {
			    height: 70%;
    			top: 15%;
    			transition:0.3s;
			}
			.container{
				transition:0.3s;
				span.head, span.cont{
					visibility:visible;
					opacity:1;
					transition:0.3s;
				}
				span.head {
					font-size:rem(28px);
				}
				span.bottom-span {
					transition:0.5s;
					transform:translate(-50%, 0px);
				}
				&:hover {
					span.head, span.cont {
						opacity:0;
						visibility:hidden;
					}
					span.bottom-span{
						transform:translate(-50%, -73px);
						background-color:$primary;
						color:$light;
					}
				}
			}
			body:not(.index) & {
				padding:rem(30px) 0;
				.bottom-span {
					bottom:rem(30px);
				}
			}
		}
	}
	@include breakpoint(large){
		.bg-cont{
			.topspan{
				font-size:rem(20px);
				padding:rem(20px) rem(22px);
			}
		}
		.full-bg {
			&:before {
				background-image:url('../images/layout/teaser-1-large.jpg');
			}
			.container:hover{
				span.bottom-span{
					transform:translate(-50%, -100px);
				}
			}
		}
	}
	@include breakpoint(giant){
		.bg-cont{
			span:not(.topspan){
				font-size:rem(48px);
				line-height:rem(55px);
			}
			body.firefox & {
				.topspan {
					min-width: rem(318px);
					text-align:center;
				}
			}
		}
		.full-bg{
			.container{
				padding:rem(40px) 0 rem(100px) 0;
				span.head {
					font-size:rem(40px);
				}
				span.cont{
					font-size:rem(18px);
				}
			}
		}
	}
	@include breakpoint(extra){
		margin-top:rem(120px);
		.bg-cont{
			padding:rem(260px) 0;
		}
	}
	@include breakpoint(huge){
		margin-top:rem(200px);
		body:not(.index) & {
			margin-top:rem(70px);
		}
		.bg-cont{
			margin-bottom:rem(20px);
			left:rem(-36px);
			padding:rem(222px) 0;
			span:not(.topspan){
				top: calc(50% + 17px);
			}
		}
		.plant-container{
			right: rem(64px);
		    width: rem(301px);
		    bottom: rem(-31px);
			background-position:bottom;
			display:inherit;
			pointer-events:none;
		}
		.full-bg{
			padding:rem(114px) 0 rem(109px) 0;
			margin-top:rem(216px);
			margin-bottom:rem(210px);
			&:before {
				background-image:url('../images/layout/teaser-1-huge.jpg');
			}
			.container{
				width:calc(100% + #{rem($base-gap *4)});
				left:rem($base-gap * -2);
				position:relative;
				padding-top:rem(56px);
				padding-bottom:rem(125px);
				span.cont{
					max-width:100%;
					left:0;
				}
				span.head {
					font-size:rem(48px);
				}
			}
			span.bottom-span {
				padding:rem(17px) rem(23px);
				body.iexplore & {
					max-height:rem(10px);
				}
			}
			body:not(.index) & {
				margin-top:rem(70px);
			}
		}
		.bg-cont-two{
			left:rem(-47px);
		}
		.holz-container{
			background-image:url('../images/layout/holz-spaehne.png');
			background-size:cover;
			position:absolute;
			right: rem(93px);
		    bottom: rem(-20px);
		    display: block;
		    width: rem(220px);
		    height: rem(140px);
		    pointer-events:none;
		}
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	.navi-add {
		display:none;
	}
	.bg-full{
		position:relative;
		background-color:$dark;
		&:before {
			content:"";
			background-image:url('../images/layout/teaser-2.jpg');
			background-repeat:no-repeat;
			background-size:cover;
			width:100%;
			height:100%;
			position:absolute;
			top:0;
			left:0;
			opacity:0.5;
		}
		.border {
			border:1px solid $light;
			color:$light;
			padding:rem(20px);
			margin:rem(40px) 0;
			z-index:2;
			position:relative;
			span.head {
				font-weight:900;
				font-size:rem(20px);
				position:relative;
				color:$light;
				display:block;
				text-decoration:none;
				max-width:80%;
				left:10%;
				text-align:center;
				a {
					color:$light;
					text-decoration:none;
					transition:0.3s;
					&:hover {
						color:$secondary;
					}
				}
			}
		}
	}
	iframe {
		height:rem(250px);
		body.nojs & {
			display:none;
		}
	}
	@include breakpoint(tiny){
		.bg-full{
			margin-top:rem(40px);
		}
	}
	@include breakpoint(small){
		.bg-full{
			&:before{
				background-image:url('../images/layout/teaser-2-small.jpg');
			}
			.border{
				padding:rem(30px) rem(20px);
				margin:rem(50px) 0;
			}
		}
	}
	@include breakpoint(medium){
		.bg-full{
			position:relative;
			.border {
				padding:rem(30px) rem(20px) rem(60px) rem(20px);
				span.head{
					font-size:rem(24px);
				}
			}
			.navi-add {
				display:flex;
				justify-content:space-between;
				position:absolute;
				bottom:0;
				left:50%;
				transform:translateX(-50%);
				background-color:$light;
				padding:rem(5px) rem(20px);
				li {
					padding:0 rem(20px);
					a{
						color:$dark;
						text-transform:uppercase;
						text-decoration:none;
					}
					&:hover{
						a{
							color:$secondary;
						}
					}
					&.active {
						a{
							color:$primary;
							font-weight:900;
						}
					}
				}
			}
		}
	}
	@include breakpoint(large){
		.bg-full{
			margin-top:rem(60px);
			&:before{
				background-image:url('../images/layout/teaser-2-large.jpg');
			}
			.border{
				padding:rem(50px) rem(20px) rem(80px) rem(20px);
				span.head{
					font-size:rem(28px);
				}
			}
		}
	}
	@include breakpoint(giant){
		.bg-full{
			.border {
				margin:rem(90px) 0;
				padding:rem(60px) rem(20px) rem(110px) rem(20px);
				span.head {
					font-size:rem(40px);
					left:0;
					max-width:100%;
				}
			}
		}
	}
	@include breakpoint(huge){
		.bg-full{
			margin-top:rem(177px);
			&:before{
				background-image:url('../images/layout/teaser-2-huge.jpg');
			}
			.border {
				width:calc(100% + #{rem($base-gap * 4)});
				left:rem($base-gap * -2);
				padding:rem(87px) rem(20px) rem(121px) rem(20px);
				margin:rem(90px) 0 rem(135px) 0;
				span.head {
					font-size:rem(48px);
				}
			}
			.navi-add {
				min-width:rem(545px);
				padding:rem(15px) rem(20px);
			}
		}
		iframe{
			height:rem(463px);
		}
	}
}

// Animation
@keyframes scroll-down {
    0%   {top:25%;}
    25%  {top:30%;}
    50%  {top:35%;}
    75% {top:30%;}
    100% {top:25%;}
}